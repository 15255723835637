import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../../Modals/Modal";
import ModalOrder from "../../Modals/Modals/ModalOrder";

function HeaderMobileMenu({ activeMobileMenu, activeMobileNavigation, toggleAdaptMenu }) {
    const [modalActive, setModalActive] = useState(false);
    return (
        <>
            <div
                className={`header__mobile-menu ${
                    activeMobileNavigation ? "header__mobile-menu_active" : ""
                }`}
            >
                <nav className="header__nav-mobile">
                    <ul className="header__nav-mobile-list">
                        <li className="header__nav-adapt-li">
                            <Link
                                to="/#works"
                                className="header__nav-adapt-link"
                                onClick={() => toggleAdaptMenu('hasDelay')}
                            >
                                портфолио
                            </Link>
                        </li>
                        <li className="header__nav-adapt-li">
                            <Link
                                to="/#services"
                                className="header__nav-adapt-link"
                                onClick={() => toggleAdaptMenu('hasDelay')}
                            >
                                услуги
                            </Link>
                        </li>
                        <li className="header__nav-adapt-li">
                            <Link
                                to="/#contacts"
                                className="header__nav-adapt-link"
                                onClick={() => toggleAdaptMenu('hasDelay')}
                            >
                                контакты
                            </Link>
                        </li>
                    </ul>
                </nav>
                <a href="tel:+74950014679" className="header__phone-adapt">
                +7 (495) 001-46-79
                </a>
                <div
                    className={`header__order-btn header__order-btn-adapt  btn btn--blue `}
                    onClick={() => setModalActive(true)}
                >
                    Оставить заявку
                </div>
            </div>
            <Modal active={modalActive} setActive={setModalActive} modalMod="mobile-bottom">
                <ModalOrder formInfo = 'Форма модальное окно, шапка, кнопка оставить заявку' />
            </Modal>
        </>
    );
}

export default HeaderMobileMenu;
