import React, { useEffect, useState } from "react";
import { ServicesBtnArrow } from "../Svgs/BTNArrow";
import "../../styles/pages/Home/HowWeWork.scss";
import { Link } from "react-router-dom";
import Modal from "../Modals/Modal";
import ModalOrder from "../Modals/Modals/ModalOrder";

function HowWeWork({ blockData, headingText, headingClasses, mod }) {
    const [modalActive, setModalActive] = useState(false);

    let formInfo = ''

    if (Array.isArray(blockData)) {
        formInfo = 'Форма модальное окно, главная страница, блок Как мы работаем'
    } else {
        formInfo = `Форма модальное окно, страница услуги ${blockData?.name}, блок Этапы работы`
    }

    return (
        <section className="how-we-work">
            <div className="container container--main-page how-we-work__container">
                <h2 className={headingClasses}>{headingText}</h2>
                <div className="how-we-work__content">
                    {Array.isArray(blockData) ? (
                        blockData?.map((item, index) => (
                            <div
                                className={`how-we-work__item dev-stage ${
                                    mod ? `dev-stage--${mod}` : ""
                                }`}
                                key={item.title}
                            >
                                <h3 className="dev-stage__number">{index + 1}</h3>
                                <h3 className="dev-stage__heading">
                                    <span className="dev-stage__number--mobile">{`${
                                        index + 1
                                    } `}</span>
                                    {item.title}
                                </h3>
                                <p className="dev-stage__text">{item.content}</p>
                            </div>
                        ))
                    ) : (
                        <>
                            <div
                                className={`how-we-work__item dev-stage ${
                                    mod ? `dev-stage--${mod}` : ""
                                }`}
                            >
                                <h3 className="dev-stage__number">1</h3>
                                <h3 className="dev-stage__heading">
                                    <span className="dev-stage__number--mobile">1</span>
                                    {blockData?.workStageTitle1}
                                </h3>
                                <p className="dev-stage__text">{blockData?.workStageContent1}</p>
                            </div>
                            <div
                                className={`how-we-work__item dev-stage ${
                                    mod ? `dev-stage--${mod}` : ""
                                }`}
                            >
                                <h3 className="dev-stage__number">2</h3>
                                <h3 className="dev-stage__heading">
                                    <span className="dev-stage__number--mobile">2</span>
                                    {blockData?.workStageTitle2}
                                </h3>
                                <p className="dev-stage__text">{blockData?.workStageContent2}</p>
                            </div>
                            <div
                                className={`how-we-work__item dev-stage ${
                                    mod ? `dev-stage--${mod}` : ""
                                }`}
                            >
                                <h3 className="dev-stage__number">3</h3>
                                <h3 className="dev-stage__heading">
                                    <span className="dev-stage__number--mobile">3</span>
                                    {blockData?.workStageTitle3}
                                </h3>
                                <p className="dev-stage__text">{blockData?.workStageContent3}</p>
                            </div>
                            <div
                                className={`how-we-work__item dev-stage ${
                                    mod ? `dev-stage--${mod}` : ""
                                }`}
                            >
                                <h3 className="dev-stage__number">4</h3>
                                <h3 className="dev-stage__heading">
                                    <span className="dev-stage__number--mobile">4</span>
                                    {blockData?.workStageTitle4}
                                </h3>
                                <p className="dev-stage__text">{blockData?.workStageContent4}</p>
                            </div>
                        </>
                    )}

                    <div
                        onClick={() => setModalActive(true)}
                        className={`btn--64 how-we-work__button btn ${
                            mod ? `how-we-work__button--${mod}` : ""
                        }`}
                    >
                        Получить бесплатную консультацию
                        <ServicesBtnArrow className="services__consultation-btn-arrow" />
                    </div>
                </div>
            </div>
            <Modal active={modalActive} setActive={setModalActive} modalMod = 'mobile-bottom'>
                <ModalOrder formInfo = {formInfo} />
            </Modal>
        </section>
    );
}

export default HowWeWork;
