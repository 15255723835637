import "../../../../styles/pages/Service/Advantages.scss";
import { AdvantagesEllipse } from "../../../Svgs/ServicePage";
import ellipseDesktop from "../../../../images/service/advantages/ellipse-desktop.svg";
import ellipseTablet from "../../../../images/service/advantages/ellipse-tablet.svg";
import ellipseMobile from "../../../../images/service/advantages/ellipse-mobile.svg";

function Advantages({ blockData }) {
    let advantagesArr = blockData?.advantages.split(";");
    advantagesArr?.pop();

    return (
        <>
            <section className="advantages section">
                <picture className="advantages__ellipse">
                    <source srcSet={ellipseDesktop} media="(min-width: 1501px)" />
                    <source srcSet={ellipseMobile} media="(max-width: 575px)" />
                    <source srcSet={ellipseTablet} media="(max-width: 1500px)" />
                    <img srcset={ellipseDesktop} alt="" />
                </picture>
                <div className="container container--main-page advantages__container">
                    <h2 className="advantages__heading heading-service">
                        Мы делаем это круто, быстро и понятно
                    </h2>
                    <div className="advantages__items">
                        {advantagesArr?.map((advantageText) => (
                            <div className="advantages__item">
                                <span className="advantages__item-arrow"></span>
                                <p className="advantages__item-description">{advantageText}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Advantages;
