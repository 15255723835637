import { createRef, useEffect, useRef } from "react";
import "../../../../styles/pages/Service/Сonditions.scss";
import "../../../../styles/pages/Service/HeadingService.scss";
import pic1 from "../../../../images/service/Сonditions/pic1.svg";
import pic2 from "../../../../images/service/Сonditions/pic2.svg";
import pic3 from "../../../../images/service/Сonditions/pic3.svg";
import { useDispatch, useSelector } from "react-redux";
import { getConditions } from "../../../../asyncActions/serviceAsync";

function Сonditions() {
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getConditions());
    }, []);

    let conditions = useSelector((store) => store.serviceReducer.conditions);

    return (
        <>
            <section className="conditions section">
                <div className="container container--main-page">
                    <h2 className="conditions__heading heading-service">Условия сотрудничества</h2>
                    <div className="conditions__items" >
                        {conditions?.map((condition) => 
                            <div className="conditions__item">
                                <div className="conditions__img-wrapper">
                                    <img src={condition.img} alt="" className="conditions__img" />
                                </div>
                                <div className="conditions__text-wrapper">
                                    <h6 className="conditions__item-title">{condition.title}</h6>
                                    <p className="conditions__item-description">
                                        {condition.description}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Сonditions;
