import React, { useEffect } from "react"
import InputMask from 'react-input-mask';
import CustomInput from '../../../UI/CustomInput';
import { ServicesBtnArrow } from "../../../Svgs/BTNArrow";


function Form({formInfo}) {
    return(
        <section className="project-page__item project-page__item--white application-form">
            <div className="project-page__section project-page__section--contacts container container--project-page">
                <form action="../mail2.php" method="POST" className="project-page__application application">
                    <input style={{ display: `none` }} name="form" value={formInfo}/>
                    <h2 className="application__heading">Составим структуру сайта и план работ</h2>
                    <h2 className="application__heading application__heading--mobile">Проконсультируем бесплатно</h2>
                    <div className="application__input-container">
                        <CustomInput id="" name="user_name" type="text"  placeholder = "Имя" className='application__input'/>
                        <InputMask required mask="+7 (999) 999-99-99" id="" name="user_phone" type="text"  placeholder = "Телефон *" className='application__input' pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}" />
                    </div>
                    <div className="application__submit-container">
                        <button className="btn--64 btn" type="submit">
                            Получить консультацию
                            <ServicesBtnArrow className="services__consultation-btn-arrow" />
                        </button>
                        <a href="/policy" className="application__policy">Нажимая на кнопку, вы даете согласие на обработку <span className="application__policy--link">персональных данных</span></a>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Form