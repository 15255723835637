import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/CommonBlocks/Footer.scss';
import { LMGLogo } from "../Svgs/LMGLogo";
import { LMGMinLogo } from "../Svgs/LMGMinLogo";


function Footer(props) {
	return (
		<section className="container--footer container container--main-page">
			<div className='footer'>
				<LMGLogo classes="footer__max-logo"/>
				<LMGMinLogo className="footer__min-logo"/>
				<div className="footer__container footer__container--first">
					<a className="footer__item" href="tel:+74950014679">+7 (495) 001-46-79</a>
					<a className="footer__item" href = "mailto:info@lm-web.ru" >info@lm-web.ru</a>
				</div>
				<div className="footer__container">
					<span className="footer__item">График работы: пн-пт с 10:00 до 19:00</span>
					<span className="footer__item">Адрес: г. Москва, ул. Михайлова д.4А</span>
				</div>
				<div className="footer__container footer__container--policy">
					<Link className="footer__item" to = "/policy">Политика конфиденциальности</Link>
				</div>
			</div>
		</section>
	)
}

export default Footer;