import Header from "../../commonBlocks/Header/Header";
import Faq from "./Faq";
import FirstSection from "./FirstSection";
import Services from "./Services";
import Form from "./Form";
import Footer from "../../commonBlocks/Footer";
import Works from "../../commonBlocks/Works";
import HowWeWork from "../../commonBlocks/HowWeWork";
import { useDispatch, useSelector } from "react-redux";
import { getHowWeWork, getSeo } from "../../../asyncActions/mainPageAsync";
import { getServices, getServicesCategories } from "../../../asyncActions/serviceAsync";
import { getWhyWe } from "../../../asyncActions/mainPageAsync";
import { getProjects } from "../../../asyncActions/projectsAsync";
import { getFaq } from "../../../asyncActions/mainPageAsync";
import WhyWe from "./WhyWe";
import React, { useEffect, useState } from "react";
import Preloader from "../../UI/Preloader";
import { Helmet } from "react-helmet";

function Home() {
    const [isLoading, setLoading] = useState(true);

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHowWeWork());
        dispatch(getSeo());
        dispatch(getServices());
        dispatch(getWhyWe());
        dispatch(getProjects());
        dispatch(getFaq());
    }, []);

    let contentHowWeWork = useSelector((store) => store.mainPageReducer.HowWeWork);
    let seoObj = useSelector((store) => store.mainPageReducer.seo);
    let servicesArr = useSelector((store) => store.serviceReducer.services);
    let whyWeArr = useSelector((store) => store.mainPageReducer.whyWe);
    let projects = useSelector((store) => store.projectsReducer.projects);
    let questions = useSelector((store) => store.mainPageReducer.faq);
    if (
        contentHowWeWork.length > 0 &&
        //seoObj.length > 0 &&
        servicesArr.length > 0 &&
        whyWeArr.length > 0 &&
        projects.length > 0 &&
        questions.length > 0
    ) {
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }
    return (
        <>
            <Helmet>
                <title>
                    Лидер медиа - разрабатываем сайты, настраиваем рекламу, проводим исследования.
                </title>
                <meta name="description" content={seoObj.description} />
            </Helmet>

            <Header btnMod="purple" textColor="white" page="Home" />
            <FirstSection />
            <Works
                projects={projects}
                cases="all"
                headingText="Кейсы"
                headingClasses="works__heading section-heading"
            />
            <Services servicesArr={servicesArr} />
            <WhyWe whyWeArr={whyWeArr} />
            <Form formInfo="Форма в центре главной страницы" />
            <HowWeWork
                headingText="Как мы работаем"
                headingClasses="how-we-work__heading section-heading"
                blockData={contentHowWeWork}
            />
            <Faq questions={questions} />
            <Form formInfo="Форма в конце главной страницы" />
            <Footer />
            {isLoading ? (
                <div class="preloader-wrapper">
                    <Preloader />
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default Home;
