import Header from "../../commonBlocks/Header/Header";
import Advantages from "./blocks/Advantages";
import Works from "../../commonBlocks/Works";
import Сonditions from "./blocks/Сonditions";
import Footer from "../../commonBlocks/Footer";
import HowWeWork from "../../commonBlocks/HowWeWork";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getServices } from "../../../asyncActions/serviceAsync";
import { getProjects } from "../../../asyncActions/projectsAsync";
import FirstBlock from "./blocks/FirstBlock";
import Reviews from "../../commonBlocks/Reviews";
import Quiz from "../../commonBlocks/Quiz";
import Form from "./blocks/Form";
import { Helmet } from "react-helmet";


function Service() {
    const { serviceName } = useParams();

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices());
        dispatch(getProjects());
    }, []);

    let services = useSelector((store) => store.serviceReducer.services);
    let projects = useSelector((store) => store.projectsReducer.projects);
    let service = services.find((service) => service.link === serviceName);

    return (
        <>
            <Helmet>
                <title>{`Лидер медиа - Услуги - ${service?.name}`}</title>    
                <meta name="description" content={service?.metaDescription} />
            </Helmet>   
            <Header btnMod="blue" />
            <FirstBlock btnMod="blue" />
            <Advantages blockData={service}/>
            <HowWeWork
                blockData={service}
                headingText="Этапы работы"
                headingClasses="how-we-work__heading-service heading-service"
                mod="service"
            />
            <Works
                projects={projects}
                service={service}
                cases={service?.works}
                headingText={`Кейсы ${service?.name}`}
                headingClasses="works__heading-service heading-service"
            />
            <Reviews />
            <Сonditions />
            <Quiz formInfo={`страница услуги ${service?.name}`} />
            <Form formInfo={`Форма в конце страницы услуги ${service?.name}`} />
            <Footer btnMod="blue" />
        </>
    );
}

export default Service;
