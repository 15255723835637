import React, { useState, useEffect } from "react";
import '../../styles/CommonBlocks/Quiz.scss';
import { useDispatch, useSelector } from "react-redux"
import { getQuiz } from "../../asyncActions/quizAsync"
import CustomInput from '../UI/CustomInput';
import InputMask from 'react-input-mask';
import { Swiper, SwiperSlide } from "swiper/react";


function Quiz({formInfo}) {
	let dispatch = useDispatch()

    useEffect(() => {
        dispatch(getQuiz())
    }, [])

    let quiz = useSelector((store) => store.quizReducer.quiz)
	const [ArrowVisibility, setArrowVisibility] = useState("none");
	const [ValidationStage, setValidationStage] = useState(false);


	return (
		<section className="quiz">
            <form onChange={() => setValidationStage(true)} action="../mail2.php" method="POST" className="container container--main-page">
                <input style={{ display: `none` }} name="form" value={`Квиз, ${formInfo}`} />
                <input name="survey_target" style={{ display: `none` }} type="text" value="target"/> 
				<h2 class="heading-service">Расскажите о вашем проекте</h2>
				<h3 className="quiz__text">Пройдите тест и получите расчет стоимости сайта</h3>
				<Swiper
						className="quiz__slider"
						slidesPerView="1"
						loop={false}
						effect="fade"
						navigation={{
							nextEl: ".quiz__arrow-next",
							prevEl: ".quiz__arrow-prev",
						}}
						spaceBetween={156}
						onSlideChange={(virtualIndex) => setArrowVisibility(virtualIndex.realIndex+1 == quiz.length ? "flex":"none")}
						>

						{quiz.map((question) => (
							<SwiperSlide className="quiz__slide">
								<div className="quiz__slide-progress">
									<span className="quiz__slide-progress-numder">{question.index}/{quiz.length}</span>
									<div className="quiz__slide-progress-bar">
										<span style={{width: `${question.index/quiz.length*100}%`}} className="quiz__slide-progress-stage"></span>
									</div>
								</div>
								{question.index!=(quiz.length-1)&&question.index!=(quiz.length) ? 
								<div className="quiz__slide-content">
								
									<div className="quiz__slide-questions">
										<div className="quiz__slide-title">{question.title}</div>

										{question.answer1 ? 
											<label for={`question${question.index}-1`} class="quiz__input-wrapper">
												<input class={question.type ? 'checkbox-button' : 'radio-button'} type={question.type ? 'checkbox' : 'radio'} id={`question${question.index}-1`} name={question.title} value={question.answer1}/>
												<span>{question.answer1}</span>     
											</label>
											: 
											<></>
										
										
											
										}
										{question.answer2 ? 
											<label for={`question${question.index}-2`} class="quiz__input-wrapper">
												<input class={question.type ? 'checkbox-button' : 'radio-button'} type={question.type ? 'checkbox' : 'radio'} id={`question${question.index}-2`} name={question.title} value={question.answer2}/>
												<span>{question.answer2}</span>     
											</label>
											: 
											<></>
										}
										{question.answer3 ? 
										<label for={`question${question.index}-3`} class="quiz__input-wrapper">
											<input class={question.type ? 'checkbox-button' : 'radio-button'} type={question.type ? 'checkbox' : 'radio'} id={`question${question.index}-3`} name={question.title} value={question.answer3}/>
											<span>{question.answer3}</span>     
										</label>
											: 
											<></>
										}
										{question.answer4 ?
										<label for={`question${question.index}-4`} class="quiz__input-wrapper">
											<input className={question.type ? 'checkbox-button' : 'radio-button'} type={question.type ? 'checkbox' : 'radio'} id={`question${question.index}-4`} name={question.title} value={question.answer4}/>
											<span>{question.answer4}</span>     
										</label>
											: 
											<></>
										}
										{question.answer5 ? 
										<label for={`question${question.index}-5`} class="quiz__input-wrapper">
											<input className={question.type ? 'checkbox-button' : 'radio-button'} type={question.type ? 'checkbox' : 'radio'} id={`question${question.index}-5`} name={question.title} value={question.answer5}/>
											<span>{question.answer5}</span>     
										</label>
											: 
											<></>
										}
									</div>
									<img className="quiz__slide-img" src={question.frontimage} />
									
								</div>
								: 
								question.index!=(quiz.length) ? 
								<div className="quiz__slide-content">
								
									<div className="quiz__slide-questions">
										<div className="quiz__slide-title">{question.title}</div>
										<textarea class="quiz__textarea" name={question.title} />
									</div>
									<img className="quiz__slide-img" src={question.frontimage} />
									
								</div>
								:
								<div className="quiz__slide-content">
								
									<div className="quiz__slide-questions">
										<div className="quiz__slide-title">{question.title}</div>
										<div class="application__input-container">
											<CustomInput 
												id="" 
												name="user_name" 
												type="text" 
												placeholder="Имя" 
												className="application__input"
											/>
											<InputMask
												required
												mask="+7 (999) 999-99-99"
												id="" 
												name="user_phone" 
												type="text" 
												placeholder="Телефон *" 
												className="application__input" 
												pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
											/>
										</div>
										<a href="/policy" className="application__policy">Нажимая на кнопку, вы даете согласие на обработку 
											<span className="application__policy--link">персональных данных</span>
										</a>
									</div>
									<img className="quiz__slide-img" src={question.frontimage} />
									
								</div>
								}
										
							</SwiperSlide>
						))}
					</Swiper>
					<div className="quiz__navigation">
						<div 
							className={`long-slider-arrow long-slider-arrow-prev quiz__arrow-prev ${ValidationStage ? '' : 'swiper-button-disabled'}`}
						>
							Предыдущий вопрос
						</div>
						<div 
							style={{display: `${ArrowVisibility=="flex"?"none":"flex"}`}} 
							className={`long-slider-arrow long-slider-arrow-next quiz__arrow-next ${ValidationStage ? '' : 'long-slider-arrow--disabled'}`}
							onClick={(evt) =>setValidationStage(false)}
						>
							Следующий вопрос
						</div>
						<button style={{display: `${ArrowVisibility}`}} className="long-slider-arrow long-slider-arrow-next" type="submit">Отправить форму</button>
					</div>
				</form>
		</section>
	)
}

export default Quiz;